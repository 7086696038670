import { toast } from 'react-toastify';
import APIs from '../apiUrl';

const ContactUs = async (payload) => {
  try {
    console.log(payload, 'ses');
    const response = await APIs.post('/api/v1/notify_me', payload);

    if (response?.data?.message === 'success') {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const VolunteersImageUpload = async (payload) => {
  try {
    const response = await APIs.post('/uploadfiles', payload);

    if (response?.data?.message === 'success') {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const RequestVolunteer = async (payload) => {
  try {
    const response = await APIs.post('/request_to_be_volunteer', payload);

    if (response?.data?.message === 'success') {
      toast('Posted successfully');
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getVolunteers = async (payload) => {
  try {
    const response = await APIs.get(
      `/get_enduser_volunteers/${payload.page}/${payload.limit}`
    );

    console.log(response?.data, 'fesical work bro');

    if (response?.data?.message === 'success') {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const volunteersServices = {
  VolunteersImageUpload,
  RequestVolunteer,
  getVolunteers,
  ContactUs,
};

export default volunteersServices;
