import styled from 'styled-components';

export const UserProfileMoreInformationDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  //   align-items: center;

  .userProfileMoreInformationWrapper {
    width: 160px;
    // margin-right: 30px;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .userProfileMoreInformationWrapper__Details {
    width: 85%;
    .userProfileMoreInformationWrapper__DetailsHeader {
      margin: 0px 0px 20px 0px;

      p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
      }
    }

    .userProfileMoreInformationWrapper__DetailsBody {
      width: 100%;

      .userProfileMoreInformationWrapper__DetailsBodyContainer {
        width: 70%;
        background: #ffffff;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
          0px 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 8px;
        padding: 24px;
        margin: 21px 0px;

        .userProfileMoreInformationWrapper__DetailsBodyTop {
          .userProfileMoreInformationWrapper__DetailsBodyTopOne {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #344054;
          }

          .userProfileMoreInformationWrapper__DetailsBodyTopTwo {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #667085;
          }
        }

        .userProfileMoreInformationWrapper__DetailsBodyBottom {
          margin-top: 24px;
          .userProfileMoreInformationWrapper__DetailsBodyBottomOne {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #344054;
          }

          .userProfileMoreInformationWrapper__DetailsBodyBottomTwo {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #667085;
          }
        }

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
`;
