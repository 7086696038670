import React, { memo } from 'react';
import UserProfileContact from './userProfileContact';
import UserProfileHeader from './userProfileHeader';
import UserProfileMoreInformation from './userProfileMoreInformation';
import { UserProfileMainDiv } from './styles/userProfileMain.style';
import { useSelector } from 'react-redux';
import UserProfileSocialMedia from './userProfileSocialMedia';

const CserProfileCompontent = () => {
  const { singleTeamPageDetails } = useSelector((state) => state.team);

  return (
    <>
      <UserProfileMainDiv>
        <div className="userAdminWrapperHeader">
          <UserProfileHeader
            firstName={singleTeamPageDetails?.firstname}
            lastName={singleTeamPageDetails?.lastname}
            jobTitle={singleTeamPageDetails?.office}
            cover_pic={singleTeamPageDetails?.cover_pic}
            status={singleTeamPageDetails?.block}
          />
        </div>

        <div className="userAdminWrapperBody">
          <div className="userAdminWrapperBodyTwo">
            <UserProfileContact
              email={singleTeamPageDetails?.email}
              phone={singleTeamPageDetails?.phone_number}
              address={singleTeamPageDetails?.address}
            />
          </div>

          <div className="userAdminWrapperBodyOne">
            <UserProfileMoreInformation
              country={singleTeamPageDetails?.country}
              state={singleTeamPageDetails?.state}
              gender={singleTeamPageDetails?.gender}
              about={singleTeamPageDetails?.about}
            />
          </div>

          <div className="userAdminWrapperBodyOne">
            <UserProfileSocialMedia
              facebook={singleTeamPageDetails?.facebook}
              instagram={singleTeamPageDetails?.instagram}
              linkedin={singleTeamPageDetails?.linkedln}
              twitter={singleTeamPageDetails?.twitter}
            />
          </div>
        </div>
      </UserProfileMainDiv>
    </>
  );
};

export default memo(CserProfileCompontent);
