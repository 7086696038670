import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { UserContactUs } from '../features/volunteers/volunteersServicesSync';
import { useDispatch, useSelector } from 'react-redux';
import { Bars } from 'react-loader-spinner';

const baseSchema = Yup.object({
  email: Yup.string().email().required(),
  name: Yup.string().required(),
  subject: Yup.string().required(),
  message: Yup.string().required(),
  phone_number: Yup.string().required(),
});

function Contact_Us() {
  const dispatch = useDispatch();

  const { isContact } = useSelector((state) => state.volunteer);

  const handleSubmited = async (value, { resetForm }) => {
    // e.preventDefault();

    const payload = {
      ...value,
    };

    await dispatch(UserContactUs(payload));

    resetForm();
  };

  const { values, handleBlur, handleChange, handleSubmit, errors } = useFormik({
    initialValues: {
      email: '',
      name: '',
      subject: '',
      message: '',
      phone_number: '',
    },
    validationSchema: baseSchema,
    onSubmit: handleSubmited,
  });
  return (
    <>
      <div class="stricky-header stricked-menu main-menu">
        <div class="sticky-header__content"></div>
      </div>

      <section class="page-header">
        <div
          class="page-header-bg"
          style={{
            backgroundImage:
              'url(assets/images/backgrounds/page-header-bg.jpg)',
          }}
        ></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>/</span>
              </li>
              <li class="active">Contact</li>
            </ul>
            <h2>Contact us</h2>
          </div>
        </div>
      </section>

      <section class="contact-three">
        <div
          class="contact-three-shape"
          style={{
            backgroundImage:
              'url(assets/images/shapes/contact-three-shape.png)',
          }}
        ></div>
        <div class="container">
          <div class="section-title text-center">
            <span class="section-title__tagline">Contact with us</span>
            <h2 class="section-title__title">
              Feel free to write us <br /> anytime
            </h2>
          </div>
          <div class="contact-page__form-box">
            <form
              action="https://pixydrops.com/oxpinshtml/main-html/assets/inc/sendemail.php"
              class="contact-page__form contact-form-validated"
              novalidate="novalidate"
            >
              <div class="row">
                <div class="col-xl-6">
                  <div class="contact-form__input-box">
                    <input
                      type="text"
                      placeholder="Your name"
                      name="name"
                      value={values?.name || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {errors.name && (
                      <p className="is-danger" style={{ color: 'red' }}>
                        {errors.name}
                      </p>
                    )}
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="contact-form__input-box">
                    <input
                      type="email"
                      placeholder="Email address"
                      name="email"
                      value={values?.email || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {errors.email && (
                      <p className="is-danger" style={{ color: 'red' }}>
                        {errors.email}
                      </p>
                    )}
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="contact-form__input-box">
                    <input
                      type="text"
                      placeholder="Phone"
                      name="phone_number"
                      value={values?.phone_number || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {errors.phone_number && (
                      <p className="is-danger" style={{ color: 'red' }}>
                        {errors.phone_number}
                      </p>
                    )}
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="contact-form__input-box">
                    <input
                      type="text"
                      placeholder="Subject"
                      name="subject"
                      value={values?.subject || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {errors.subject && (
                      <p className="is-danger" style={{ color: 'red' }}>
                        {errors.subject}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12">
                  <div class="contact-form__input-box text-message-box">
                    <textarea
                      name="message"
                      placeholder="Write a message"
                      value={values?.message || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>

                    {errors.message && (
                      <p className="is-danger" style={{ color: 'red' }}>
                        {errors.message}
                      </p>
                    )}
                  </div>
                  <div class="contact-form__btn-box">
                    {isContact ? (
                      <Bars
                        height="80"
                        width="80"
                        color="#0716A6"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    ) : (
                      <button
                        onClick={handleSubmit}
                        type="submit"
                        class="thm-btn contact-form__btn"
                      >
                        Send a message
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section class="contact-one">
        <div class="container">
          <div class="contact-one__inne">
            <ul class="list-unstyled contact-one__list">
              <li class="contact-one__single">
                <div class="contact-one__icon">
                  <span class="icon-phone-call"></span>
                </div>
                <div class="contact-one__content">
                  <p class="contact-one__sub-title">Helpline</p>
                  <h3 class="contact-one__number">
                    <a href="tel:2348157858222">+2348157858222</a>
                  </h3>
                </div>
              </li>
              <li class="contact-one__single">
                <div class="contact-one__icon">
                  <span class="icon-message"></span>
                </div>
                <div class="contact-one__content">
                  <p class="contact-one__sub-title">Send email</p>
                  <h3 class="contact-one__number">
                    <a href="mailto:support@ictahrae.com">
                      support@ictahrae.com
                    </a>
                  </h3>
                </div>
              </li>
              <li class="contact-one__single">
                <div class="contact-one__icon">
                  <span class="icon-location"></span>
                </div>
                <div class="contact-one__content">
                  <p class="contact-one__sub-title">
                    Adjacent to # 7, mid-town hotel Loko,
                  </p>
                  <h3 class="contact-one__number">
                    along Nyanya-Karshi express FCT, Abuja.
                  </h3>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section class="google-map google-map-two">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.490613999345!2d7.556322174779392!3d8.926855691130484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0fccd521c6cb%3A0x25ae3b3e456b34b9!2sNyanya-Karshi%20Road%2C%20AMAC%2C%20Federal%20Capital%20Territory!5e0!3m2!1sen!2sng!4v1706909631242!5m2!1sen!2sng"
          class="google-map__one"
          style={{ border: 0 }}
          allowfullscreen
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>
    </>
  );
}

export default Contact_Us;
