import React, { memo } from 'react';
import { BsCircleFill } from 'react-icons/bs';
import { UserProfileHeaderDiv } from './styles/userProfileHeader.style';

const UserProfileHeader = ({
  firstName,
  lastName,
  jobTitle,
  status,
  cover_pic,
}) => {
  return (
    <UserProfileHeaderDiv>
      <div className="userProfileHeaderWrapper__Image">
        <div className="userProfileHeaderWrapper__ImageWrapper">
          {cover_pic ? (
            <img src={`${cover_pic}`} />
          ) : (
            <p>{firstName?.slice(0, 1)}</p>
          )}
        </div>
      </div>

      <div className="userProfileHeaderWrapper__Details_container">
        <div className="userProfileHeaderWrapper__Details">
          <h3>
            {firstName} {lastName}
          </h3>
          <p>{jobTitle}</p>
          {/* <div className="userProfileHeaderWrapper__DetailsContainer">
            <div className="userProfileHeaderWrapper__DetailsContainerIconBody">
              <BsCircleFill
                className={` ${
                  status
                    ? 'userProfileHeaderWrapper__DetailsContainerIconBlock'
                    : 'userProfileHeaderWrapper__DetailsContainerIcon'
                }`}
              />
            </div>
            <p>{status ? 'Blocked' : 'Not Blocked'}</p>
          </div> */}
        </div>
      </div>
    </UserProfileHeaderDiv>
  );
};

export default memo(UserProfileHeader);
