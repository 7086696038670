import styled from 'styled-components';

export const UserProfileMainDiv = styled.div`
  width: 100%;

  .userAdminWrapperHeader {
    width: 100%;

    @media screen and (max-width: 767px) {
      height: 100%;
    }
  }

  .userAdminWrapperBody {
    width: 100%;
    margin: 72px 0px 30px 0px;

    .userAdminWrapperBodyOne {
      width: 100%;
    }

    .userAdminWrapperBodyTwo {
      width: 100%;
      margin: 36px 0px 0px 0px;
    }
  }
`;
