import APIs from '../apiUrl';

const getTeam = async (payload) => {
  try {
    const response = await APIs.get(
      `/get_enduser_ministry/${payload.page}/${payload.limit}`
    );

    console.log(response?.data, 'fesical work bro');

    if (response?.data?.message === 'success') {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSingleTeam = async (payload) => {
  try {
    const response = await APIs.get(`/get_single_minister/${payload}`);

    if (response?.data?.message === 'success') {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const TeamServices = {
  getTeam,
  getSingleTeam,
};

export default TeamServices;
