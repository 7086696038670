import { createAsyncThunk } from '@reduxjs/toolkit';
import ServicesServices from './servicesServices';
import { toast } from 'react-toastify';

export const GetServices = createAsyncThunk(
  'services/getServices',
  async (payload) => {
    try {
      let response = await ServicesServices.getServices(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetServicesNav = createAsyncThunk(
  'services/getServicesNav',
  async (payload) => {
    try {
      let response = await ServicesServices.getServices(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GetSingleServices = createAsyncThunk(
  'services/getSingleServices',
  async (payload) => {
    try {
      let response = await ServicesServices.getSingleServices(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
