import React, { useEffect } from 'react';
import UserProfilePage from '../Components/UserProfile';
import { TeamDiv } from './Styles/team.style';
import { useParams } from 'react-router-dom';
import { GetSingleTeam } from '../features/team/teamServicesSync';
import { useDispatch, useSelector } from 'react-redux';
import LoaderScreen from '../utils/loaderScreen';

const SingleTeam = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const { teamLoading } = useSelector((state) => state.team);

  useEffect(() => {
    dispatch(GetSingleTeam(id));
  }, [id]);

  return (
    <>
      <TeamDiv>
        <div class="stricky-header stricked-menu main-menu">
          <div class="sticky-header__content"></div>
        </div>

        <section class="page-header">
          <div
            class="page-header-bg"
            style={{
              backgroundImage:
                'url(assets/images/backgrounds/page-header-bg.jpg)',
            }}
          ></div>
          <div class="container">
            <div class="page-header__inner">
              <ul class="thm-breadcrumb list-unstyled">
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li>
                  <span>/</span>
                </li>
                <li class="active">Teams</li>
              </ul>
              <h2>Teams</h2>
            </div>
          </div>
        </section>

        <section class="team-one">
          <div class="container">
            {/* <div class="row"></div> */}
            {teamLoading ? <LoaderScreen /> : <UserProfilePage />}
          </div>
        </section>
      </TeamDiv>
    </>
  );
};

export default SingleTeam;
