import styled from 'styled-components';

export const UserContactDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  //   align-items: center;

  .userAdminContactWrapper__SectionOne {
    width: 160px;
    // margin-right: 30px;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .userAdminContactWrapper__BodyWrapper {
    width: 85%;

    .userAdminContactWrapperHeader {
      margin: 0px 0px 20px 0px;

      p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
      }
    }

    .userAdminContactWrapper__Body {
      width: 100%;

      .userAdminContactWrapper__BodyContainer {
        width: 70%;
        background: #ffffff;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
          0px 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 8px;
        padding: 24px;
        margin: 21px 0px;

        .userAdminContactWrapper__BodyContainerTop {
          .userAdminContactWrapper__BodyContainerTopOne {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #344054;
          }

          a {
            text-decoration: none;
          }

          .userAdminContactWrapper__BodyContainerTopTwo {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #667085;
          }
        }

        .userAdminContactWrapper__BodyContainerBottom {
          margin-top: 24px;
          .userAdminContactWrapper__BodyContainerBottomOne {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #344054;
          }

          a {
            text-decoration: none;
          }

          .userAdminContactWrapper__BodyContainerBottomTwo {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #667085;
          }
        }

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
`;
