import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../../Assets/health_png.png';
import { NavStyle } from './styles.nav';
import { NavContext } from '../../App';
import { useSelector } from 'react-redux';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

// 0072BC

function Nav() {
  const { setOpenSideBar } = useContext(NavContext);

  const { servicesArticleDetailsNav } = useSelector((state) => state.services);

  console.log(servicesArticleDetailsNav?.docs, 'house issue');

  return (
    <NavStyle>
      <header class="main-header-two" style={{ position: 'sticky', top: 0 }}>
        <nav class="main-menu main-menu-two">
          <div class="main-menu-two__wrapper">
            <div
              class="main-menu-two__wrapper-inner"
              style={{ backgroundColor: '#fff' }}
            >
              <div class="main-menu-two__left">
                <div class="main-menu-two__logo">
                  <NavLink to="/">
                    <img src={Logo} alt="" />
                  </NavLink>
                </div>
                {/* <div class="main-menu-two__shape-1 float-bob-x">
                  <img
                    src="assets/images/shapes/main-menu-shape-1.png"
                    alt=""
                  />
                </div> */}
              </div>
              <div class="main-menu-two__right">
                <div class="main-menu-two__right-top">
                  <div class="main-menu-two__right-top-left">
                    <div class="main-menu-two__volunteers">
                      <div class="main-menu-two__volunteers-icon">
                        <img
                          src="assets/images/icon/main-menu-heart-icon.png"
                          alt=""
                        />
                      </div>
                      <div class="main-menu-two__volunteers-text-box">
                        <p class="main-menu-two__volunteers-text">
                          <NavLink to="/be_a_volunteer">
                            Become a <span>volunteers</span>
                          </NavLink>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="main-menu-two__right-top-right">
                    <div class="main-menu-two__right-top-address">
                      <ul class="list-unstyled main-menu-two__right-top-address-list">
                        <li>
                          <div class="icon">
                            <span class="icon-phone-call"></span>
                          </div>
                          <div class="content">
                            <p>Helpline</p>
                            <h5>
                              <a href="tel:2348157858222">+2348157858222</a>
                            </h5>
                          </div>
                        </li>
                        <li>
                          <div class="icon">
                            <span class="icon-message"></span>
                          </div>
                          <div class="content">
                            <p>Send email</p>
                            <h5>
                              <a href="mailto:support@ictahrae.com">
                                support@ictahrae.com
                              </a>
                            </h5>
                          </div>
                        </li>
                        <li>
                          <div class="icon">
                            <span class="icon-location"></span>
                          </div>
                          <div class="content">
                            <p>Adjacent to # 7, mid-town hotel Loko,</p>
                            <h5>along Nyanya-Karshi express FCT, Abuja.</h5>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="main-menu-two__right-bottom">
                  <div
                    class="main-menu-two__main-menu-box"
                    style={{
                      padding: '10px 10px 10px 50px',
                    }}
                  >
                    <a
                      href="#"
                      class="mobile-nav__toggler"
                      onClick={() => {
                        setOpenSideBar(true);
                      }}
                    >
                      <i class="fa fa-bars"></i>
                    </a>
                    <ul class="main-menu__list">
                      <li>
                        <NavLink to="/">Home</NavLink>
                      </li>
                      <li class="dropdown">
                        <NavLink to="/about">About Us</NavLink>
                        <ul>
                          <li>
                            <NavLink to="/about">About Us</NavLink>
                          </li>
                          <li>
                            <NavLink to="/team">Team</NavLink>
                          </li>
                          <li>
                            <NavLink to="/volunteer">Volunteer</NavLink>
                          </li>
                          {/* <li>
                            <NavLink to="/volunteer">Career</NavLink>
                          </li> */}
                          <li>
                            <NavLink to="/faq">FAQ</NavLink>
                          </li>
                        </ul>
                      </li>
                      <li class="dropdown">
                        <NavLink to="/services">Services</NavLink>
                        <ul>
                          <li>
                            <NavLink to="/services">Services</NavLink>
                          </li>

                          {servicesArticleDetailsNav?.docs?.length > 0 ? (
                            servicesArticleDetailsNav?.docs?.map(
                              (item, key) => (
                                <li key={key}>
                                  <NavLink to={`/services/${item?.title}`}>
                                    {item?.title}
                                  </NavLink>
                                </li>
                              )
                            )
                          ) : (
                            <SkeletonTheme
                              baseColor="#1F1F1F33"
                              highlightColor="#444"
                            >
                              <div>
                                <Skeleton width="100%" height="0.2rem" />
                              </div>
                            </SkeletonTheme>
                          )}

                          {/* <li>
                            <NavLink to="/services/Biomedical%20&%20Behavioral%20Research">
                              Biomedical & Behavioral Research
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/services/Care%20&%20Support">
                              Care & Support
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/services/Sexual%20Health%20Programs">
                              Sexual Health Programs
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/services/Mental%20Health">
                              Mental Health
                            </NavLink>
                          </li> */}
                        </ul>
                      </li>
                      <li class="dropdown">
                        <NavLink to="/project">InnoSphere</NavLink>
                        <ul>
                          <li>
                            <NavLink to="/project">Project</NavLink>
                          </li>
                          <li>
                            <NavLink to="/resourse">Resource</NavLink>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <NavLink to="/gallery">Gallery</NavLink>
                      </li>
                      <li>
                        <NavLink to="/blog">Blog</NavLink>
                      </li>

                      <li>
                        <NavLink to="/contact">Contact</NavLink>
                      </li>
                    </ul>
                  </div>
                  <div class="main-menu-two__main-menu-content-box">
                    <div class="main-menu-two__search-cat-btn-box">
                      <div class="main-menu-two__btn-box">
                        <NavLink to="/donate">
                          <a class="main-menu-two__btn">
                            <span class="fa fa-heart"></span>
                            Donate now
                          </a>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </NavStyle>
  );
}

export default Nav;
