import { createSlice } from '@reduxjs/toolkit';
import {
  GetServices,
  GetServicesNav,
  GetSingleServices,
} from './servicesServicesSync';

const initialState = {
  isError: false,
  isSuccess: false,
  message: '',
  serviceLoading: false,
  serviceLoadingProps: false,
  singleServicesDetails: {},
  servicesArticleDetails: {},
  servicesArticleDetailsNav: {},
  serviceslimit: 9,
  servicesPage: 1,
};

export const servicesSlices = createSlice({
  name: 'services',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
      state.serviceLoading = false;
      state.singleServicesDetails = {};
      state.servicesArticleDetails = {};
      state.serviceslimit = 9;
      state.servicesPage = 1;
    },

    updateServicesPage: (state, action) => {
      state.servicesPage = action.payload;
    },
  },
  extraReducers: {
    [GetServices.pending]: (state, action) => {
      if (state.servicesArticleDetails?.docs?.length <= 0) {
        state.serviceLoading = true;
      }
    },
    [GetServices.fulfilled]: (state, action) => {
      state.serviceLoading = false;
      state.message = action.payload?.message;
      state.servicesArticleDetails = action?.payload?.data;
    },
    [GetServices.rejected]: (state, action) => {
      state.serviceLoading = false;
      state.message = action.payload?.message;
    },

    [GetServicesNav.pending]: (state, action) => {
      if (state.servicesArticleDetails?.docs?.length <= 0) {
        state.serviceLoadingProps = true;
      }
    },
    [GetServicesNav.fulfilled]: (state, action) => {
      state.serviceLoadingProps = false;
      state.message = action.payload?.message;
      state.servicesArticleDetailsNav = action?.payload?.data;
    },
    [GetServicesNav.rejected]: (state, action) => {
      state.serviceLoadingProps = false;
      state.message = action.payload?.message;
    },

    [GetSingleServices.pending]: (state, action) => {
      state.serviceLoading = true;
    },

    [GetSingleServices.fulfilled]: (state, action) => {
      state.serviceLoading = false;
      state.message = action.payload?.message;
      state.singleServicesDetails = action.payload?.data;
    },

    [GetSingleServices.rejected]: (state, action) => {
      state.serviceLoading = false;
      state.message = action.payload?.message;
    },
  },
});

export const { reset, updateServicesPage } = servicesSlices.actions;

export default servicesSlices.reducer;
