import React, { useEffect } from 'react';
import Logo from '../../Assets/health_png.png';
import { FooterDiv } from './styles.footer';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetSocial } from '../../features/social/socialServicesSync';

function Footer() {
  const dispatch = useDispatch();

  const currentYear = new Date().getFullYear();

  const { socialMediaDetails } = useSelector((state) => state.social);

  useEffect(() => {
    dispatch(GetSocial());
  }, []);

  return (
    <footer class="site-footer">
      <div
        class="site-footer-bg"
        style={{
          backgroundImage: 'url(assets/images/backgrounds/site-footer-bg.jpg)',
        }}
      ></div>
      <div class="site-footer__top">
        <div class="container">
          <div className="row rowDetails">
            <div
              class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div class="footer-widget__column footer-widget__about">
                <div class="footer-widget__about-logo">
                  <a href="index.html">
                    <img
                      src={Logo}
                      alt=""
                      width={60}
                      height={60}
                      style={{ objectFit: 'cover' }}
                    />
                  </a>
                </div>
                <div class="footer-widget__about-text-box">
                  <p class="footer-widget__about-text">
                    ICTHARAE’s goal is to provide adequate health care and human
                    rights services for marginalized persons in Nigeria, as a
                    community focused organization.
                  </p>
                </div>
                <div class="footer-widget__btn">
                  <NavLink to="/donate">
                    <span class="fa fa-heart"></span>Donate now
                  </NavLink>
                </div>
              </div>
            </div>
            <div
              class="col-xl-2 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="200ms"
            >
              <div class="footer-widget__column footer-widget__links clearfix">
                <h3 class="footer-widget__title">Links</h3>
                <ul class="footer-widget__links-list list-unstyled clearfix">
                  <li>
                    <NavLink to="/about">About us</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact">Contact</NavLink>
                  </li>
                  <li>
                    <NavLink to="/blog">Latest News</NavLink>
                  </li>
                  <li>
                    <NavLink to="/project">Project</NavLink>
                  </li>
                  <li>
                    <NavLink to="/donate">Donations</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="col-xl-2 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div class="footer-widget__column footer-widget__non-profit clearfix">
                <h3 class="footer-widget__title">Others</h3>
                <ul class="footer-widget__non-profit-list list-unstyled clearfix">
                  <li>
                    <NavLink to="/create_testimony">Create Testimony</NavLink>
                  </li>
                  <li>
                    <NavLink to="/be_a_volunteer">Become a volunteer</NavLink>
                  </li>
                  <li>
                    <NavLink to="/team">Team</NavLink>
                  </li>
                  <li>
                    <NavLink to="/services">Services</NavLink>
                  </li>
                  <li>
                    <NavLink to="/resourse">Resourse</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div class="footer-widget__column footer-widget__contact">
                <h3 class="footer-widget__title">Contact</h3>
                <p class="footer-widget__contact-text">
                  Adjacent to # 7, mid-town hotel Loko, <br /> along
                  Nyanya-Karshi express FCT, Abuja.
                </p>
                <ul class="list-unstyled footer-widget__contact-list">
                  <li>
                    <div class="icon">
                      <i class="fa fa-envelope"></i>
                    </div>
                    <div class="text">
                      <p>
                        <a href="mailto:support@ictahrae.com">
                          support@ictahrae.com
                        </a>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="icon">
                      <i class="fas fa-phone-alt"></i>
                    </div>
                    <div class="text">
                      <p>
                        <a href="tel:2348157858222">+2348157858222</a>
                      </p>
                    </div>
                  </li>
                </ul>
                <div class="site-footer__social">
                  <NavLink
                    to={`${socialMediaDetails?.twitter}`}
                    target="_blank"
                  >
                    <i class="fab fa-twitter"></i>
                  </NavLink>

                  <NavLink
                    to={`${socialMediaDetails?.facebook}`}
                    target="_blank"
                  >
                    <i class="fab fa-facebook"></i>
                  </NavLink>

                  <NavLink
                    to={`${socialMediaDetails?.linkedin}`}
                    target="_blank"
                  >
                    <i class="fab fa-pinterest-p"></i>
                  </NavLink>
                  <NavLink
                    to={`${socialMediaDetails?.instagram}`}
                    target="_blank"
                  >
                    <i class="fab fa-instagram"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="site-footer__bottom">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="site-footer__bottom-inner">
                <p class="site-footer__bottom-text">
                  {` © All Copyright ${currentYear} by ICTHARAE`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
