import React, { useEffect } from 'react';
import Image1 from '../Assets/1705324218558.jpg';
import Image2 from '../Assets/20240115_130858.jpg';
import Image3 from '../Assets/IMG-20240115-WA0022_1.jpg';
import Image4 from '../Assets/IMG-20240115-WA0061.jpg';
import Image5 from '../Assets/IMG_20240110_205642.jpg';
import { TeamDiv } from './Styles/team.style';
import { useDispatch, useSelector } from 'react-redux';
import { reset, updateTeamPage } from '../features/team/teamSlices';
import { GetTeam } from '../features/team/teamServicesSync';
import Img from 'react-cloudinary-lazy-image';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { DomPaginationState } from '../Components/DOM_Pagination_State';
import LoaderScreen from '../utils/loaderScreen';
import { NotFound } from './Styles/notFound';
import { NavLink } from 'react-router-dom';

function Team() {
  const dispatch = useDispatch();

  const { teamArticleDetails, teamLoading, teamlimit, teamPage } = useSelector(
    (state) => state.team
  );

  useEffect(() => {
    dispatch(GetTeam({ page: teamPage, limit: teamlimit }));

    return () => {
      dispatch(reset());
    };
  }, []);

  const nextPage = () => {
    if (!teamLoading) {
      if (teamArticleDetails?.hasNextPage) {
        dispatch(updateTeamPage(teamPage + 1));

        dispatch(
          GetTeam({
            page: teamPage + 1,
            limit: teamlimit,
          })
        );
      }
    }
  };

  const prevPage = () => {
    if (!teamLoading) {
      if (teamArticleDetails?.hasPrevPage) {
        dispatch(updateTeamPage(teamPage - 1));

        dispatch(
          GetTeam({
            page: teamPage - 1,
            limit: teamlimit,
          })
        );
      }
    }
  };

  console.log(teamArticleDetails?.docs, 'frontend');

  return (
    <TeamDiv>
      <div class="stricky-header stricked-menu main-menu">
        <div class="sticky-header__content"></div>
      </div>

      <section class="page-header">
        <div
          class="page-header-bg"
          style={{
            backgroundImage:
              'url(assets/images/backgrounds/page-header-bg.jpg)',
          }}
        ></div>
        <div class="container">
          <div class="page-header__inner">
            <ul class="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>/</span>
              </li>
              <li class="active">Teams</li>
            </ul>
            <h2>Teams</h2>
          </div>
        </div>
      </section>

      <section class="team-one">
        <div class="container">
          <div class="row">
            {teamLoading ? (
              <LoaderScreen />
            ) : teamArticleDetails?.docs?.length > 0 ? (
              teamArticleDetails?.docs?.map((item, key) => (
                <div
                  class="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="100ms"
                >
                  <div class="team-one__single">
                    <div class="team-one__img">
                      <img
                        src={item?.cover_pic}
                        alt=""
                        className="teamImage"
                        style={{ objectFit: 'cover' }}
                      />

                      {/* <LazyLoadImage
                      src={item?.cover_pic}
                      alt=""
                      className="teamImage"
                      style={{ objectFit: "cover" }}
                      visibleByDefault={true}
                    /> */}

                      {/* <Img
                      cloudName={"cloud"}
                      imageName={item?.item?.cover_pic}
                      fluid={{
                        maxWidth: 300,
                        height: 300,
                      }}
                      style={{
                        width: "40vw",
                        height: "20vh",
                      }}
                      urlParams={item?.cover_pic}
                    /> */}

                      <div class="team-one__social">
                        <a href={`${item?.facebook}`}>
                          <i class="fab fa-facebook"></i>
                        </a>
                        <a href={`${item?.twitter}`}>
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a href={`${item?.linkedln}`}>
                          <i class="fab fa-pinterest-p"></i>
                        </a>
                        <a href={`${item?.instagram}`}>
                          <i class="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                    <div class="team-one__content">
                      <h3 class="team-one__name">
                        {/* <a href="javascript:void(0);">{`${item?.firstname} ${item?.lastname}`}</a> */}
                        <NavLink to={`/team/${item?._id}`}>
                          <a href="javascript:void(0);">{`${item?.firstname} ${item?.lastname}`}</a>
                        </NavLink>
                      </h3>
                      <p class="team-one__sub-title">{`${item?.office}`}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <NotFound>
                <div className="not_found_wrapper">
                  <h3 class="team-one__name">
                    <a href="team-details.html" style={{ color: '#283734' }}>
                      No Team found
                    </a>
                  </h3>
                </div>
              </NotFound>
            )}

            {teamArticleDetails?.docs?.length && (
              <DomPaginationState
                onNext={nextPage}
                onPrev={prevPage}
                skip={teamArticleDetails?.page}
                loading={teamLoading}
                hasNext={teamArticleDetails?.hasNextPage}
                hasPrev={teamArticleDetails?.hasPrevPage}
              />
            )}
          </div>
        </div>
      </section>
    </TeamDiv>
  );
}

export default Team;
