import { createSlice } from '@reduxjs/toolkit';
import { GetSingleTeam, GetTeam } from './teamServicesSync';

const initialState = {
  isError: false,
  isSuccess: false,
  message: '',
  teamLoading: false,
  teamArticleDetails: {},
  teamlimit: 9,
  teamPage: 1,
  singleTeamPageDetails: {},
};

export const teamSlices = createSlice({
  name: 'team',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
      state.teamLoading = false;
      state.teamArticleDetails = {};
      state.teamlimit = 9;
      state.teamPage = 1;
      state.singleTeamPageDetails = {};
    },

    updateTeamPage: (state, action) => {
      state.teamPage = action.payload;
    },
  },
  extraReducers: {
    [GetTeam.pending]: (state, action) => {
      if (state.teamArticleDetails?.docs?.length <= 0) {
        state.teamLoading = true;
      }
    },

    [GetTeam.fulfilled]: (state, action) => {
      state.teamLoading = false;
      state.message = action.payload?.message;
      state.teamArticleDetails = action?.payload?.data;
    },

    [GetTeam.rejected]: (state, action) => {
      state.teamLoading = false;
      state.message = action.payload?.message;
    },

    [GetSingleTeam.pending]: (state, action) => {
      state.teamLoading = true;
    },

    [GetSingleTeam.fulfilled]: (state, action) => {
      state.teamLoading = false;
      state.message = action.payload?.message;
      state.singleTeamPageDetails = action.payload?.data;
    },

    [GetSingleTeam.rejected]: (state, action) => {
      state.teamLoading = false;
      state.message = action.payload?.message;
    },
  },
});

export const { reset, updateTeamPage } = teamSlices.actions;

export default teamSlices.reducer;
